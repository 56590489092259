import React from "react";

function List() {
  return (
    <div className="mt-8 m-4 border-t pt-4 font-light">
      Copyright 2025 Jellybean Lab All Rights Reserved.{" "}
      <a href="https://campusjobs.co/">Campus</a>
    </div>
  );
}

export default List;
